import {
  IBooleanV3TimelineItemDto,
  IFullV3TimelineItemDto,
  IGapV3TimelineItemDto,
  IOverlapV3TimelineItemDto,
} from '@bo-schema-api-dto';

export function getActiveTimelineItem<
  T extends IGapV3TimelineItemDto | IFullV3TimelineItemDto | IBooleanV3TimelineItemDto,
>(timeline: T[]): T | undefined {
  return getTimelineItemAtDate(timeline, new Date());
}

export function getTimelineItemAtDate<
  T extends IGapV3TimelineItemDto | IFullV3TimelineItemDto | IBooleanV3TimelineItemDto,
>(timeline: T[], date: Date | undefined): T | undefined {
  if (!Array.isArray(timeline)) {
    return undefined;
  }

  return timeline.find((item) => isTimelineItemActive(item, date));
}

export function getActiveTimelineItems<T extends IOverlapV3TimelineItemDto>(
  timeline: T[],
): T[] | undefined {
  return getTimelineItemsAtDate(timeline, new Date());
}

export function getTimelineItemsAtDate<T extends IOverlapV3TimelineItemDto>(
  timeline: T[],
  date: Date | undefined,
): T[] | undefined {
  if (!Array.isArray(timeline)) {
    return undefined;
  }

  return timeline.filter((item) => isTimelineItemActive(item, date));
}

export function isTimelineItemActive(item: TimelineV3ItemDto, date: Date | undefined): boolean {
  // if item starts from beginning of time it is always active
  if (item.from === undefined && date === undefined) {
    return true;
  }

  date = date && new Date(date.toISOString().substring(0, 10));

  return (
    (item.from === undefined || (date !== undefined && item.from <= date)) &&
    (item.to === undefined || (date !== undefined && item.to >= date))
  );
}

export function getHasTimelineFutureItems<T extends IOverlapV3TimelineItemDto>(
  timeline: T[],
): boolean {
  if (!Array.isArray(timeline)) {
    return false;
  }
  return timeline.some((item) => isTimelineItemFuture(item, new Date()));
}

function isTimelineItemFuture(item: TimelineV3ItemDto, date: Date | undefined): boolean {
  date = date && new Date(date.toISOString().substring(0, 10));
  return item.from !== undefined && date !== undefined && item.from > date;
}

export function getActiveAndFutureTimelineItems<T extends IOverlapV3TimelineItemDto>(
  timeline: T[],
): T[] | undefined {
  if (!Array.isArray(timeline)) {
    return undefined;
  }
  return timeline.filter((item) => isTimelineItemActiveOrFuture(item, new Date()));
}

export function isTimelineItemActiveOrFuture(
  item: TimelineV3ItemDto,
  date: Date | undefined,
): boolean {
  return isTimelineItemFuture(item, date) || isTimelineItemActive(item, date);
}

export type TimelineV3ItemDto =
  | IGapV3TimelineItemDto
  | IFullV3TimelineItemDto
  | IOverlapV3TimelineItemDto
  | IBooleanV3TimelineItemDto;
