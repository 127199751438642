import { Directive, computed, input } from '@angular/core';
import { FormControl, NgControl, NgModel, Validators } from '@angular/forms';

@Directive({
  standalone: true,
  selector: 'label[diRequiredAsterisk]',
  host: {
    '[class.required-label]': 'isRequired()',
    '[class.required-top]': 'isRequired()',
  },
})
export class RequiredAsteriskDirective {
  readonly value = input.required<NgControl | NgModel | FormControl | boolean>({
    alias: 'diRequiredAsterisk',
  });

  readonly isRequired = computed(() => {
    const value = this.value();
    if (typeof value === 'boolean') {
      return value;
    }
    if (value instanceof NgControl) {
      return value.control.hasValidator(Validators.required);
    }
    if (value instanceof NgModel) {
      return value.control.hasValidator(Validators.required);
    }
    if (value instanceof FormControl) {
      return value.hasValidator(Validators.required);
    }
    return false;
  });
}
